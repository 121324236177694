import { render, staticRenderFns } from "./Empleados.vue?vue&type=template&id=ded4105a"
import script from "./Empleados.vue?vue&type=script&lang=js"
export * from "./Empleados.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QToolbar,QBtn,QTooltip,QToolbarTitle,QSeparator,QInput,QSelect,QIcon,QPopupProxy,QDate});qInstall(component, 'directives', {ClosePopup});
