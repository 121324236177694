<template>
    <div class="q-pa-md">
        <!-- DIALOG PARA VER LOS EVENTOS -->
        <q-dialog v-model="dialogEvent" transition-show="scale" transition-hide="scale">
            <q-card style="width: 800px">
                <q-card-section>
                    <template v-if="selectedEvent">
                        <div class="row">
                            <div class="col text-left">
                                <span class="text-h6">
                                    {{ selectedEvent.title }}
                                </span>
                            </div>
                            <div class="col text-right">
                                <span class="text-h6">
                                    {{ selectedEvent.start && selectedEvent.start.format('DD/MMMM/YYYY HH:mm') }}
                                </span>
                                <q-btn round style="background:#1A4161; color:white" icon="mdi-whatsapp"
                                    @click="GetDatosWhatsAsync" />
                            </div>
                        </div>
                    </template>
                    <div class="element-linea"></div>
                </q-card-section>
                <q-card-section>
                    <template v-if="selectedEvent">
                        <span class="text-h5">
                            {{ selectedEvent.content }}
                        </span>
                        <br>
                        <span class="text-h6" v-html="selectedEvent.contentFull">
                        </span>
                    </template>
                    <q-input mask="(###) ### - ####" fill-mask dense filled v-model="numTelefono" label="Teléfono"
                        style="min-width: 300px" />
                </q-card-section>
                <q-separator />
                <q-card-actions vertical>
                    <!-- <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                        style="background:#1A4161; color:white" @click="OpenDialogCobrar()">
                        Cobrar
                    </q-btn> -->
                    <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                        style="background:#1A4161; color:white" @click="dialogCancelaCita = true">
                        Cancelar Cita
                    </q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG DE RELACION DE USUARIOS -->
        <q-dialog v-model="dialogUsuarios" transition-show="scale" transition-hide="scale" persistent>
            <RelacionUsuarios @cierraVentana="dialogUsuarios = false"></RelacionUsuarios>
        </q-dialog>

        <!-- DIALOG PARA DAR DE ALTA UN NUEVO PACIENTE -->
        <q-dialog full-width v-model="dialogPaciente" transition-show="scale" transition-hide="scale" persistent>
            <q-card class="full-width">
                <!-- <q-card-section class="bg-primary text-white"> -->
                <q-card-section>
                    <q-toolbar>
                        <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close" dense
                            @click="CloseDialogPaciente()">
                            <q-tooltip>
                                Cerrar
                            </q-tooltip>
                        </q-btn>
                        <q-toolbar-title>
                            Paciente
                        </q-toolbar-title>
                        <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-content-save"
                            @click="PostPaciente()">
                            <q-tooltip>
                                Guardar
                            </q-tooltip>
                        </q-btn>
                    </q-toolbar>
                    <q-separator class="full-width" color="primary" inset size="4px" />
                </q-card-section>
                <q-card-section>
                    <Paciente @cierraVentana="dialogPaciente = false"></Paciente>
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA CANCELAR UNA CITA -->
        <q-dialog v-model="dialogCancelaCita" transition-show="scale" transition-hide="scale">
            <q-card style="width: 800px">
                <q-card-section>
                    <template v-if="selectedEvent">
                        <div class="row">
                            <div class="col text-left">
                                <span class="text-h6">
                                    Cancelar Cita
                                </span>
                            </div>
                        </div>
                    </template>
                    <div class="element-linea"></div>
                </q-card-section>
                <q-card-section>
                    <template v-if="selectedEvent">
                        <span class="text-h5">
                            Confirmar cancelación de cita para:
                        </span>
                        <br>
                        <span class="text-h5">
                            {{ selectedEvent.title }}
                        </span>
                        <br>
                        <span class="text-h5">
                            Motivo de consulta:
                        </span>
                        <br>
                        <span class="text-h5">
                            {{ selectedEvent.content }}
                        </span>
                    </template>
                    <div class="row">
                        <div class="col-12">
                            <q-select outlined dense v-model="selectCancelacion" :options="optionsCancelacion"
                                label="¿Quién solicita la cancelación?" />
                        </div>
                        <div class="col-12">
                            <span>Notas</span>
                            <q-editor v-model="textCancelacion" min-height="5rem" label="aaa" />
                        </div>
                    </div>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn style="background:#1A4161; color:white" unelevated rounded
                        @click="CloseDialogCancelaCitas()">
                        Cancelar
                    </q-btn>
                    <q-btn style="background:#1A4161; color:white" unelevated rounded @click="PostCancelaCita()">
                        Aceptar
                    </q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA NOTIFICAR A TODOS LOS PACIENTES DEL DIA -->
        <q-dialog v-model="dialogNotificaPacientes" transition-show="scale" transition-hide="scale">
            <q-card style="min-width: 1000px">
                <q-card-section>
                    <div class="row">
                        <div class="col text-left">
                            <span class="text-h6">
                                Notificación masiva
                            </span>
                        </div>
                    </div>
                    <div class="element-linea"></div>
                </q-card-section>
                <q-card-section>
                    <q-table title="Pacientes" :data="listConsultasMasivo" :columns="columnsNotificacionPacientes"
                        row-key="nombre" :pagination="pagination" dense>
                        <template v-slot:top-right>
                            <q-input filled v-model="dateCitasDia" mask="date" :rules="['date']" dense>
                                <template v-slot:append>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                            <q-date v-model="dateCitasDia">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="OK" color="primary" flat />
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                            <q-btn round style="background:#1A4161; color:white" class="q-ml-sm q-mb-md"
                                icon="mdi-magnify" @click="GetCitasDia()" />
                        </template>
                        <template v-slot:body="props">
                            <q-tr :props="props">
                                <q-td key="paciente" :props="props">{{ props.row.paciente }}</q-td>
                                <q-td key="motivo_consulta" :props="props">{{ props.row.motivo_consulta }}</q-td>
                                <q-td key="inicio" :props="props">{{ FormateadoFechas(props.row.inicio) }}</q-td>
                            </q-tr>
                        </template>
                    </q-table>
                </q-card-section>
                <q-card-actions align="right">
                    <q-btn size="md" class="btn-estilos q-mr-sm" unelevated rounded
                        style="background:#1A4161; color:white" @click="PostMenjaseWhatsAppMasivo()">
                        Notificar Pacientes
                    </q-btn>
                    <q-btn size="md" class="btn-estilos q-mr-sm" unelevated rounded
                        style="background:#1A4161; color:white" @click="PostMenjaseWhatsAppMedico()">
                        Notificar Médico
                    </q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>

        <!-- DIALOG PARA HACER UN COBRO -->
        <q-dialog full-width v-model="dialogCobro" transition-show="scale" transition-hide="scale">
            <Cobros @cierraCobro="dialogCobro = false"></Cobros>
        </q-dialog>

        <!-- DIALOG PARA MOSTRAR LAS CUENTAS POR COBRAR -->
        <q-dialog full-width v-model="dialogCuentasPorCobrar" transition-show="scale" transition-hide="scale">
            <CuentasPorCobrar @cierraCuentaPorCobrar="dialogCuentasPorCobrar = false"></CuentasPorCobrar>
        </q-dialog>

        <!-- CABECERA -->
        <div class="row">
            <div class="col text-left">
                <span v-if="$store.state.isDark == true"
                    class="q-pa-lg shadow-6 mb-3 inline-block surface-card logo-inicio"
                    style="border-radius: 10px; width: 230px; height: 50px;">
                </span>

                <span v-else class="q-pa-lg shadow-0 mb-3 inline-block surface-card logo-inicio-negro"
                    style=" width: 230px; height: 50px;">
                </span>
            </div>
            <div class="col text-center">
                <div class="text-h4">Agenda</div>
            </div>
            <div class="col text-right">
            </div>
        </div>
        <div class="row">
            <!-- AGENDA -->
            <div class="col-12 col-md-8">
                <vue-cal locale="es" ref="vuecal" :time-from="horaInicio" :time-to="horaFin" :time-step="30"
                    :watchRealTime="true" :disable-views="['years', 'year']" :events="events" today-button
                    :on-event-click="onEventClick" id="vuecal" :time-cell-height="timeCellHeight" active-view="week"
                    :selectedDate="selectedDate">
                    <template #today-button>
                        <q-btn round flat icon="my_location" color="blue-10" />
                    </template>
                </vue-cal>
            </div>
            <!-- DATOS DEL MEDICO Y DEL CONSULTORIO -->
            <div class="col-12 col-md-4 q-gutter-sm">
                <q-expansion-item class="shadow-1 overflow-hidden" label="Datos Generales" header-class="text-white"
                    expand-icon-class="text-white" flat v-model="expandedGeneral" style="background:#1A4161;">
                    <q-card class="full-width">
                        <q-card-section>
                            <div class="row q-col-gutter-sm">
                                <!-- UNIDAD MEDICA -->
                                <div class="col-12" v-if="unidadMedica">
                                    <q-input filled v-model="unidadMedica.nombre" label="Unidad médica" dense
                                        readonly />
                                </div>
                                <!-- USUARIOS -->
                                <div class="col-12">
                                    <q-select v-model="selectMedico" emit-value map-options @filter="filtroMedicos"
                                        :options="itemsFiltroMedicos" use-input input-debounce="0"
                                        option-label="username" dense filled label="Médico"
                                        virtual-scroll-slice-size="1" />
                                </div>
                            </div>
                        </q-card-section>
                        <q-card-actions vertical>
                            <q-btn size="md" class="btn-estilos q-mr-sm" unelevated rounded
                                style="background:#1A4161; color:white" @click="GetCitas()">Consultar
                                Agenda</q-btn>
                            <q-btn size="md" class="btn-estilos q-mr-sm" unelevated rounded
                                style="background:#1A4161; color:white" @click="OpenDialogUsuarios()">
                                Usuarios Relacionados
                            </q-btn>
                        </q-card-actions>
                    </q-card>
                </q-expansion-item>
                <!-- GENERAR NUEVA CONSULTA -->
                <template v-if="banderaCitas">
                    <q-expansion-item class="shadow-1 overflow-hidden" label="Nueva Cita" header-class="text-white"
                        expand-icon-class="text-white" flat style="background:#1A4161;">
                        <q-card class="full-width">
                            <q-card-section>
                                <div class="row q-col-gutter-sm">
                                    <!-- MOTIVOS DE CONSULTA -->
                                    <div class="col-12">
                                        <q-select v-model="selectMotivoConsulta" emit-value map-options
                                            @filter="filtroMotivosConsulta" :options="itemsFiltroMotivoConsulta"
                                            use-input input-debounce="0" option-label="motivo" dense filled
                                            label="Motivo de Consulta" virtual-scroll-slice-size="1"
                                            new-value-mode="add" />
                                    </div>
                                    <!-- ALTA DE NUEVO PACIENTE -->
                                    <template v-if="selectMotivoConsulta === 'PRIMERA CONSULTA'">
                                        <div class="col-12">
                                            <q-btn size="md" class="btn-estilos q-mr-sm full-width" unelevated rounded
                                                style="background:#1A4161; color:white"
                                                @click="OpenDialogPaciente()">Alta de Paciente</q-btn>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <!-- PACIENTE -->
                                        <div class="col-12">
                                            <q-select v-model="selectPaciente" emit-value map-options
                                                @filter="filtroPacientes" :options="itemsfiltroPacientes" use-input
                                                input-debounce="0" option-label="paciente" dense filled label="Paciente"
                                                virtual-scroll-slice-size="1" new-value-mode="add" />
                                        </div>
                                        <!-- FECHA DE CONSULTA -->
                                        <div class="col-12">
                                            <q-date class="custom-q-date" v-model="fecha" landscape today-btn
                                                @input="CambioFecha()" />
                                        </div>
                                        <!-- HORA INICIO DE LA CONSULTA -->
                                        <div class="col-12">
                                            <q-input filled v-model="selectHoraI" mask="time" :rules="['time']"
                                                label="Hora Inicio" dense>
                                                <template v-slot:append>
                                                    <q-icon name="access_time" class="cursor-pointer">
                                                        <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                            <q-time v-model="selectHoraI">
                                                                <div class="row items-center justify-end">
                                                                    <q-btn v-close-popup label="Close" color="primary"
                                                                        flat />
                                                                </div>
                                                            </q-time>
                                                        </q-popup-proxy>
                                                    </q-icon>
                                                </template>
                                            </q-input>
                                        </div>
                                        <!-- HORA FIN DE LA CONSULTA -->
                                        <div class="col-12">
                                            <q-input filled v-model="selectHoraF" mask="time" :rules="['time']"
                                                label="Hora Fin" dense>
                                                <template v-slot:append>
                                                    <q-icon name="access_time" class="cursor-pointer">
                                                        <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                            <q-time v-model="selectHoraF">
                                                                <div class="row items-center justify-end">
                                                                    <q-btn v-close-popup label="Close" color="primary"
                                                                        flat />
                                                                </div>
                                                            </q-time>
                                                        </q-popup-proxy>
                                                    </q-icon>
                                                </template>
                                            </q-input>
                                        </div>
                                        <!-- NOTAS -->
                                        <div class="col-12">
                                            <span>Notas</span>
                                            <q-editor v-model="selectNotas" min-height="5rem" label="aaa" />
                                        </div>
                                    </template>
                                </div>
                            </q-card-section>
                            <q-card-actions vertical>
                                <template v-if="selectMotivoConsulta != 'PRIMERA CONSULTA'">
                                    <q-btn size="md" class="btn-estilos q-mr-sm" unelevated rounded
                                        style="background:#1A4161; color:white" @click="PostConsulta()">
                                        Agendar Consulta
                                    </q-btn>
                                </template>
                            </q-card-actions>
                        </q-card>
                    </q-expansion-item>
                    <q-card class="full-width">
                        <q-card-actions vertical>
                            <q-btn size="md" class="btn-estilos q-mr-sm" unelevated rounded
                                style="background:#1A4161; color:white" @click="OpenNotificacionMasivaPacientes()">
                                Notificación Masiva
                            </q-btn>
                            <q-btn size="md" class="btn-estilos q-mr-sm" unelevated rounded
                                style="background:#1A4161; color:white" @click="OpenDialogCuentasPorCobrar()">
                                Cuentas por cobrar
                            </q-btn>
                        </q-card-actions>
                    </q-card>
                </template>

                <!-- ASIGNAR INCIDENCIAS -->
                <!-- <template v-if="banderaCitas">
                    <q-expansion-item class="shadow-1 overflow-hidden" label="Eventos"
                        header-class="bg-primary text-white" expand-icon-class="text-white" flat>
                    </q-expansion-item>
                </template> -->
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { QSpinnerCube } from 'quasar';
import { date } from 'quasar'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import Paciente from '../Pacientes/FichaIdentificacion.vue';
import RelacionUsuarios from './RelacionUsuarios.vue';
import Cobros from '../Cobros/Cobros.vue'
import CuentasPorCobrar from '../Cobros/ListCobros.vue'
import moment from 'moment';
import { parse } from 'date-fns';

export default {
    components: {
        VueCal,
        Paciente,
        RelacionUsuarios,
        Cobros,
        CuentasPorCobrar,
    },
    data() {
        return {
            unidadMedica: null,
            selectedDate: "",
            timeCellHeight: 40,
            events: [],
            listConsultas: [],

            //VENTANA PARA VER INFORMACION DEL EVENTO
            dialogEvent: false,
            selectedEvent: null,
            now: new Date(),
            numTelefono: "",

            //APARTADO DE LOS DATOS GENERALES DE LA AGENDA
            expandedGeneral: true,

            //APARTADO DE LAS CITAS NUEVAS
            selectMedico: null,
            fecha: '',
            selectPaciente: null,
            selectMotivoConsulta: 'PRIMERA CONSULTA',
            selectHoraI: '00:00',
            selectHoraF: '00:00',
            selectNotas: '',
            itemsfiltroPacientes: null,
            itemsFiltroMotivoConsulta: null,
            itemsFiltroMedicos: null,

            citaDefault: {
                paciente: '',
                id_paciente: '',
                medico: '',
                id_medico: '',
                consultorio: '',
                id_consultorio: '',
                motivo_consulta: '',
                notas: '',
                inicio: '2024-07-30 10:00',
                fin: '2024-07-30 11:00',
                class: 'cita',
            },

            //ALTA DE PACIENTE
            dialogPaciente: false,

            //CONSULTORIOS
            itemsConsultorios: [],
            itemsFiltroConsultorios: [],
            selectConsultorio: null,

            banderaCitas: false,

            //PARA RELACIONAR USUARIOS
            dialogUsuarios: false,

            itemsMedicos: [],

            //CANCELACION DE CITAS
            dialogCancelaCita: false,
            optionsCancelacion: ["Paciente", "Médico"],
            selectCancelacion: null,
            textCancelacion: "",

            //NOTIFICACION A PACIENTES MASIVO
            listConsultasMasivo: [],
            dialogNotificaPacientes: false,
            dateCitasDia: "",
            pagination: {
                // sortBy: "nombre",
                descending: false,
                page: 1,
                rowsPerPage: 10,
            },
            columnsNotificacionPacientes: [
                { name: "paciente", align: "center", label: "Paciente", field: "paciente", sortable: true, },
                { name: "motivo_consulta", align: "center", label: "Motivo de consulta", field: "motivo_consulta", sortable: true, },
                { name: "inicio", align: "center", label: "Fecha", field: "inicio", sortable: true, },
            ],

            //COBROS
            dialogCobro: false,
            dialogCuentasPorCobrar: false,

            horaInicio: 0,
            horaFin: 1440
        }
    },

    computed: {
        token() {
            return this.$store.state.usuario;
        },

        itemsPacientes() {
            return this.$store.state.listaPacienteStore
        },

        itemsMotivoConsulta() {
            return this.$store.state.listaCatalogoMotivosConsulta;
        },

    },

    watch: {
        itemsMedicos(newLista) {
            if (newLista.length > 0) {
                this.selectMedico = newLista[0]; // Toma el primer elemento por defecto
            } else {
                this.selectMedico = null; // Restablece el valor si la lista está vacía
            }
        }
    },

    created() {
        this.Iniciales();
    },

    mounted() {
        this.GetUsuariosRelacionados()
    },

    methods: {
        async Iniciales() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Estamos validando unos datos. Espere...', messageColor: 'white' })
            const tareas = [
                this.fechaActual(),
                this.GetPacientes(),
                this.GetUnidadMedica(),
                this.GetConsultorioMedico(),
            ];
            const paso2 = await Promise.all(tareas);
            this.$q.loading.hide()
        },

        async GetUnidadMedica() {
            try {
                const _id = this.token.id_unidad_medica;
                let response = await axios.get(`UnidadMedica/GetUnidadMedicaIdAsync/${_id}`);
                this.unidadMedica = response.data;
            } catch (error) {
                console.log(error);
            }
        },

        async GetUsuariosRelacionados() {
            this.itemsMedicos = [];
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Consultando. Espere...', messageColor: 'white' })
            try {
                const id_usuario = this.token._id;
                const id_unidad_medica = this.token.id_unidad_medica
                let response = await axios.get(`Agenda/GetUsuariosRelacionadosAsync/${id_usuario}/${id_unidad_medica}`);
                this.itemsMedicos = response.data;
                this.$q.loading.hide()
            } catch (error) {
                this.$q.loading.hide()
            }
        },

        async GetPacientes() {
            try {
                const base_ = this.token.base;
                let response = await axios.get(`Paciente/GetDatosPacientes/${base_}`);
                let catalogo = response.data;
                this.$store.state.listaPacienteStore = catalogo;
            } catch (error) {
                console.log(error);
            }
        },

        async GetConsultorioMedico() {
            try {
                const base_ = this.token.base;
                const idMedico = this.token._id;
                let response = await axios.get(`Configuracion/GetConsultorioMedicoAsync/${base_}/${idMedico}`);
                let x = { ...response.data }
                this.idConsultorio = x._id;
                this.referencia = x.referencia;
                const hi = await this.convertirHoraAMinutos(x.horaInicio);
                const hf = await this.convertirHoraAMinutos(x.hofaFin);
                this.horaInicio = hi;
                this.horaFin = hf;
            } catch (error) {
                console.log(error);
            }
        },

        async convertirHoraAMinutos(hora) {
            const [horas, minutos] = hora.split(':').map(Number);
            return horas * 60 + minutos;
        },

        fechaActual() {
            const timeStamp = Date.now()
            const formattedString = date.formatDate(timeStamp, 'YYYY/MM/DD');
            this.fecha = formattedString;
            this.selectedDate = formattedString;
        },

        onEventClick(event, e) {
            const idPaciente = event._idPaciente
            const listaPacientes = this.$store.state.listaPacienteStore;
            const paciente = listaPacientes.find(f => f._id === idPaciente)
            if (!paciente) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Verifique los datos del paciente.',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }
            this.numTelefono = paciente.celular
            this.selectedEvent = event
            this.dialogEvent = true
            e.stopPropagation()
        },

        //FILTROS
        filtroPacientes(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsfiltroPacientes = this.itemsPacientes.filter(v => v.paciente.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsfiltroPacientes.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroMotivosConsulta(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroMotivoConsulta = this.itemsMotivoConsulta.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroMotivoConsulta.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        filtroMedicos(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroMedicos = this.itemsMedicos.filter(v => v.username.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroMedicos.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        CambioFecha() {
            const timeStamp = new Date(this.fecha)
            const formattedString = date.formatDate(timeStamp, 'YYYY/MM/DD');
            // this.fecha = formattedString;
            this.selectedDate = formattedString;
        },

        //CONSULTAS
        async PostConsulta() {
            if (!this.fecha) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione una fecha válida',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            //CREAMOS LAS VARIABLES
            const motivoConsulta = this.selectMotivoConsulta;
            const paciente = this.selectPaciente;
            const fecha = this.fecha.replaceAll('/', '-');
            const fechaInicio = fecha + " " + this.selectHoraI;
            const fechaFin = fecha + " " + this.selectHoraF;
            const fechaCreacion = moment().format('YYYY/MM/DD HH:mm:ss');
            const usuarioCreador = this.token.username
            const medico = this.selectMedico;
            const notas = this.selectNotas;
            const base_ = this.token.base;

            //VALIDAMOS
            if (!motivoConsulta) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione el motivo de la consulta',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            if (!paciente) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione un paciente de la lista, o de de alta uno nuevo, seleccionando le motivo de consulta: PRIMERA CONSULTA',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            if (fechaInicio === fechaFin) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Las fechas no pueden ser iguales',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            //VALIDAMOS QUE LA FECHA FINAL NO SEA MENOS QUE LA FECHA INICIAL
            const date1 = new Date(fechaInicio);
            const date2 = new Date(fechaFin);
            if (date1 >= date2) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Las fecha final no puede ser menor a la fecha inicial',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            //CREAMOS LOS OBJETOS
            const cita = {
                _id: '',
                paciente: paciente.paciente,
                id_paciente: paciente._id,
                medico: medico.nombre + " " + medico.apellido,
                id_medico: medico.id_usuario,
                consultorio: null,
                id_consultorio: null,
                motivo_consulta: motivoConsulta,
                notas: notas,
                inicio: fechaInicio,
                fin: fechaFin,
                class_: 'nuevo',
                usuarioCreador: usuarioCreador,
                fechaCreacion: fechaCreacion,
                estatus: "Nueva",
            };

            //GUARDAMOS
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando Cita. Espere...', messageColor: 'white' })
                let response = await axios.post(`Agenda/PostCitaAsync/${base_}/`, cita);
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `Cita generada exitosamente.` })
                this.GetCitas();
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        async GetCitas() {
            if (!this.selectMedico) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Seleccione un médico de la lista',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                return;
            }

            this.$store.state.listCobrosStore = [];
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Consultando Cita. Espere...', messageColor: 'white' })
            try {
                const base_ = this.token.base;
                const medico = this.token._id;
                let response = await axios.get(`Agenda/GetCitasAsync/${base_}/${medico}/`);
                let listaCitas = response.data;
                this.events = [];
                this.listConsultas = [...listaCitas];

                for (let c of listaCitas) {
                    //VALIDAMOS EL ESTATUS DE LA CITA
                    let clase_ = "";
                    if (c.estatus === "Nueva") {
                        clase_ = "nuevo";
                    } else if (c.estatus === "Notificado") {
                        clase_ = "notificado";
                    }
                    //FORMATEADO DE LAS FECHAS
                    let inicio = this.FormateadoFechas(c.inicio)
                    let fin = this.FormateadoFechas(c.fin);
                    let cita = {
                        start: inicio,
                        end: fin,
                        title: c.paciente,
                        content: c.motivo_consulta,
                        contentFull: c.notas,
                        class: clase_,
                        _idPaciente: c.id_paciente,
                        _idConsultorio: c.id_consultorio,
                        medico: c.medico,
                        id_consulta: c._id,
                    }
                    this.events.push(cita);
                }
                this.banderaCitas = true;
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al consultar intentelo de nuevo.', color: 'red' })
            }
        },

        async PutEstatusCita(idConsulta, estatus) {
            try {
                const base_ = this.token.base;
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Actualizando. Espere...', messageColor: 'white' })
                let response = await axios.put(`Agenda/PutEstatusCitaAsync/${base_}/${idConsulta}/${estatus}`);
                this.$q.notify({ type: 'positive', message: `Estatus actualizado correctamente.` })
                this.$q.loading.hide()
                let indice = this.events.findIndex(item => item.id_consulta === idConsulta)
                if (indice) {
                    if (estatus === "Notificado") {
                        this.events[indice].class = "notificado";
                    } else if (estatus === "Cancelado") {
                        this.events.splice(indice, 1);
                    }
                }
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al actualizar, intente nuevamente.', color: 'red' })
            }
        },

        //ALTA PACIENTES
        OpenDialogPaciente() {
            let paciente = {
                _id: '',
                estatus: 'Vigente',
                fechaElaboracion: null,
                especialidad: '',
                referencia: '',
                apellidos: '',
                nombre: '',
                fechaNacimiento: null,
                edad: 0,
                genero: '',
                seguroMedico: '',
                numPoliza: '',
                vive: '',
                datosContacto: {
                    correo: '',
                    pais: '',
                    celular: '',
                    calle: '',
                    numInterior: '',
                    numExterior: '',
                    codigoPostal: '',
                    colonia: '',
                },
                contactoUrgencia: {
                    nombre: '',
                    correo: '',
                    pais: '',
                    celular: '',
                },
                contactoResponsable: {
                    nombre: '',
                    correo: '',
                    pais: '',
                    celular: '',
                },
                antecHeredofammiliares: [],
                antecPersonalesNoPatologicos: {
                    procedencia: {
                        pais: '',
                        estado: '',
                        municipio: {
                            estado: '',
                            municipio: '',
                        },
                    },
                    residencia: {
                        pais: '',
                        estado: '',
                        municipio: {
                            estado: '',
                            municipio: '',
                        },
                    },
                    escolaridad: '',
                    religion: '',
                    ocupacion: '',
                    estadoCivil: '',
                    convAnimales: [],
                    zoonosis: [],
                    actividadesFisicas: [],
                    comidasDia: 1,
                    calidadAlimentacion: 0,
                    intolerancias: [],
                    alergias: [],
                    hemotipo: '',
                    exposicionBiomasa: {
                        horas: 0,
                        años: 0,
                    },
                    tabaquismo: {
                        numCigarros: 0,
                        años: 0,
                        indice: 0,
                    },
                    alcoholismo: [],
                    toxicomanias: [],
                },
                antecPersonalesPatologicos: {
                    enfermedadesCronicos: [],
                    prodedimientosQuirurgicos: [],
                    traumatismosPrevios: [],
                },
                antecGinecoObstetricos: {
                    activa: '',
                    actividadSexual: {
                        edadVidaSexual: 0,
                        numGestas: 0,
                        numPartos: 0,
                        numCesareas: 0,
                        numAbortos: 0,
                        numParejas: 0,
                        orientacion: '',
                        practicasRiesgo: '',
                        mpf: '',
                        enfermedadesTS: [],
                    },
                    edadMenarca: '',
                    estatusMenopausia: '',
                    menopausia: {
                        cicloMenstrual: '',
                        frecuencia: 0,
                        diasSangrado: 0,
                    },
                    fechaUltimaMenstruacion: null,
                },
                antecAndrologicos: {
                    activa: '',
                    actividadSexual: {
                        edadVidaSexual: 0,
                        numParejas: 0,
                        orientacion: '',
                        mpf: '',
                        practicasRiesgo: '',
                        enfermedadesTS: [],
                    },
                    circuncidado: '',
                    edadPubarca: 0,
                },
                antecOncologicos: {
                    terapias: [],
                    radioterapias: [],
                    procedimientos: []
                },
                padecimientoActual: {
                    fechaInicio: null,
                    sintomas: [],
                    diagnosticos: [],
                    descripcion: '',
                },
                usuarioCreador: this.token.username
            }

            this.$store.state.pacienteStore = paciente
            this.dialogPaciente = true;
        },

        CloseDialogPaciente() {
            this.dialogPaciente = false;
        },

        async PostPaciente() {
            try {
                const base_ = this.token.base;
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando Paciente. Espere...', messageColor: 'white' })
                let paciente = this.$store.state.pacienteStore
                paciente.fechaElaboracion = moment().format('YYYY/MM/DD');
                paciente.edad = this.calcularEdad(paciente.fechaNacimiento)
                paciente.usuarioCreador = this.token.username
                let response = await axios.post(`Paciente/PostPaciente/${base_}/`, paciente);
                paciente._id = response.data

                //AGREGAMOS LA CITA AL STORE
                this.$store.commit('postPaciente', paciente);
                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El paciente ha sido guardado exitosamente.` })
                this.CloseDialogPaciente();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        calcularEdad(fechaNacimiento) {
            var fechaNacimientoObj = new Date(fechaNacimiento);
            var fechaActual = new Date();
            var diferenciaEnMilisegundos = fechaActual - fechaNacimientoObj;
            var edad = Math.floor(diferenciaEnMilisegundos / 1000 / 60 / 60 / 24 / 365.25);
            return edad;
        },

        FormateadoFechas(fecha) {
            const date = new Date(fecha);

            // Obtener los componentes de la fecha
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
            const day = String(date.getUTCDate()).padStart(2, '0');
            const hours = String(date.getUTCHours()).padStart(2, '0'); // Sumar una hora para el formato deseado
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');

            // Crear la cadena en el formato deseado
            const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
            return formattedDate;
        },

        FormateadoFechasDos(fecha) {
            moment.locale('es');
            const date = new Date(fecha);
            let formattedDate = moment(date).format('DD-MMMM-YYYY HH:mm');
            return formattedDate;
        },

        FormatoFechaTres(fecha) {
            const fechaNueva = parse(fecha, 'dd-MMMM-yyyy HH:mm', new Date());
            return fechaNueva;
        },

        //USUARIOS RELACIONADOS
        async OpenDialogUsuarios() {
            //MOSTRAMOS EL DIALOG
            this.dialogUsuarios = true;
        },

        //NOTIFICACIONES MASIVAS
        async GetCitasDia() {
            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Consultando Cita. Espere...', messageColor: 'white' })
                const base_ = this.token.base;
                const fecha = this.dateCitasDia;
                const fecha_ = fecha.replaceAll("/", "-");
                const medico = this.token._id;
                let response = await axios.get(`Agenda/GetCitasAsync/${base_}/${medico}/${fecha_}`);
                let listaCitas = response.data;
                this.listConsultasMasivo = [];
                this.listConsultasMasivo = [...listaCitas];
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al consultar intentelo de nuevo.', color: 'red' })
            }
        },

        async OpenNotificacionMasivaPacientes() {
            //DAMOS FORMATO A LA FECHA
            const timeStamp = Date.now()
            const formattedString = date.formatDate(timeStamp, 'YYYY/MM/DD');
            this.dateCitasDia = formattedString;
            this.listConsultasMasivo = [];
            this.dialogNotificaPacientes = true;
        },

        async PostMenjaseWhatsAppMasivo() {
            // this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Generando mensajes...', messageColor: 'white' })
            for (var i of this.listConsultasMasivo) {
                const base_ = this.token.base;
                const response = await axios.get(`Paciente/GetDatosWhatsAsync/${base_}/${i.id_paciente}`);
                const fecha = this.FormateadoFechas(i.inicio);
                const catalogo = response.data;
                const paciente = i.paciente
                const medico = i.medico
                const consultorio = this.unidadMedica.nombre;
                const ubicacionMaps = this.unidadMedica.liga_maps;
                const fechaHoraConsulta = fecha
                const motivoConsulta = i.motivo_consulta
                const celular = response.celular
                const notas = i.notas;

                const objMensaje = {
                    telefono: celular,
                    paciente: paciente,
                    medico: medico,
                    consultorio: consultorio,
                    ubicacionMaps: ubicacionMaps,
                    fechaHoraConsulta: fechaHoraConsulta,
                    motivoConsulta: motivoConsulta,
                    notas: notas,
                }

                if (celular != "" && celular != null) {
                    await this.PostMensajeWhatsapp(objMensaje, i._id, true)
                } else {
                    this.$q.notify({ type: 'negative', message: `El paciente; ${paciente}, no cuenta con número de celular.`, color: 'red' })
                }
            }
        },

        async PostMenjaseWhatsAppMedico() {
            const celular = this.token.telefono;
            if (celular === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `El médico no tiene un número asosciado, no es posible notificar por este medio`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            if (this.listConsultasMasivo.length == 0) {
                this.$q.notify({
                    type: 'warning',
                    message: `No se encontraron citas para notificar, consulte otra fecha`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
                return;
            }

            //RECORREMOS LA LISTA DE EVENTOS, PARA CREAR EL MENSAJE
            let objMensaje = {
                telefono: celular,
                clinica: this.unidadMedica.nombre,
                fecha: this.dateCitasDia,
                detalles: [],
            };
            // console.log(this.listConsultasMasivo)
            for (let a of this.listConsultasMasivo) {
                let fecha = a.inicio
                let mensaje = {
                    fecha: fecha,
                    paciente: a.paciente,
                    motivoConsulta: a.motivo_consulta
                }
                objMensaje.detalles.push(mensaje);
            }
            // console.log(mensaje)
            await this.PostMensajeWhatsMedico(objMensaje, 0, false)

        },

        //CANCELAR CITAS
        CloseDialogCancelaCitas() {
            this.selectCancelacion = null;
            this.textCancelacion = "";
            this.dialogEvent = false;
            this.dialogCancelaCita = false;
        },

        async PostCancelaCita() {
            try {
                const id_cita = this.selectedEvent.id_consulta;
                const usuario_creador = this.token.username;
                const fecha_creacion = new Date();
                const solicitante = this.selectCancelacion;
                const notas = this.textCancelacion;

                if (!solicitante) {
                    this.$q.notify({
                        type: 'warning',
                        message: `Indique quién solicita la cancelación`,
                        actions: [
                            { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                        ]
                    })
                    return;
                }
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Generando Cancelación...', messageColor: 'white' })
                const objCancelacion = {
                    _id: "",
                    id_cita: id_cita,
                    usuario_creador: usuario_creador,
                    fecha_creacion: fecha_creacion,
                    solicitante: solicitante,
                    notas: notas,
                }
                await this.PutEstatusCita(id_cita, "Cancelado");
                const base_ = this.token.base;
                let response = await axios.post(`Agenda/PostCancelacionCitaAsync/${base_}/`, objCancelacion);
                await this.GetCitas();
                this.CloseDialogCancelaCitas();
                this.dialogEvent = false;
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive',
                    message: `Cancelación exitosa`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'negative',
                    message: `Error al cancelar, intente nuevamente`,
                    actions: [
                        { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                    ]
                })
            }
        },

        //WHATSAPP
        async GetDatosWhatsAsync() {
            try {
                const paciente = this.selectedEvent.title;
                const medico = this.selectedEvent.medico;
                const consultorio = this.unidadMedica.nombre;
                const ubicacionMaps = this.unidadMedica.liga_maps;
                const fechaHoraConsulta = this.selectedEvent.start.format('DD/MMMM/YYYY HH:mm');
                const motivoConsulta = this.selectedEvent.content;
                const notas = this.selectedEvent.contentFull;
                const celular = this.numTelefono;

                const objMensaje = {
                    telefono: celular,
                    paciente: paciente,
                    medico: medico,
                    consultorio: consultorio,
                    ubicacionMaps: ubicacionMaps,
                    fechaHoraConsulta: fechaHoraConsulta,
                    motivoConsulta: motivoConsulta,
                    notas: notas,
                }
                console.log(objMensaje)
                if (celular != "" && celular != null) {
                    await this.PostMensajeWhatsapp(objMensaje, this.selectedEvent.id_consulta, true)
                } else {
                    this.$q.notify({ type: 'negative', message: 'El paciente no cuenta con número de celular.', color: 'red' })
                }
            } catch (error) {
                console.log(error)
            }
        },

        async PostMensajeWhatsapp(objMensaje, idConsulta, updateEstatus) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Enviadno notificación...', messageColor: 'white' })
            try {
                const response = await axios.post(this.$store.state.rutaNotificacionesStore + "WhatsApp/PostConsultaPacienteAsync", objMensaje);
                if (response.status == 200) {
                    // const idConsulta = this.selectedEvent.id_consulta;
                    this.$q.notify({ type: 'positive', message: `Notificación enviada.` })
                    if (updateEstatus) {
                        await this.PutEstatusCita(idConsulta, "Notificado");
                        this.dialogEvent = false;
                    }
                }
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al notificar, intente nuevamente.', color: 'red' })
            }
        },

        async PostMensajeWhatsMedico(objMensaje) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Enviadno notificación...', messageColor: 'white' })
            try {
                console.log(objMensaje)
                const response = await axios.post(this.$store.state.rutaNotificacionesStore + "WhatsApp/PostConsultasMedicoAsync", objMensaje);
                if (response.status == 200) {
                    this.$q.notify({ type: 'positive', message: `Notificación enviada.` })
                }
                this.$q.loading.hide()
            } catch (error) {
                console.log(error);
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al notificar, intente nuevamente.', color: 'red' })
            }
        },

        //COBROS
        async OpenDialogCobrar() {
            // console.log(this.selectMedico)
            let x = this.selectedEvent;
            const fecha = this.FormateadoFechasDos(x.start)
            const ObjCobro = {
                item: 0,
                id_consulta: x.id_consulta,
                id_paciente: x._idPaciente,
                paciente: x.title,
                id_medico: this.selectMedico.id_usuario_principal,
                medico: x.medico,
                id_consultorio: x._idConsultorio,
                descripcion: x.content,
                cantidad: 1,
                medida: "",
                precio_unitario: 0,
                precio: 0,
                fecha: fecha,
                telefono: this.numTelefono,
                clave: "",
            }
            this.$store.state.cobrosStore = { ...ObjCobro }
            this.dialogCobro = true;
        },

        OpenDialogCuentasPorCobrar() {
            const medico = this.selectMedico.id_usuario;
            // const consultorio = this.selectConsultorio._id;
            this.$store.state.idMedicoStore = medico;
            // this.$store.state.idConsultorioStore = consultorio;
            this.dialogCuentasPorCobrar = true;
        },
    }
}
</script>
<style>
/* CABECERA */
.vuecal__menu {
    background-color: #1A4161
}

.vuecal__title-bar {
    background-color: #205366
}

.vuecal__title span {
    color: #ffffff
}

.vuecal__title button {
    color: #ffffff
}

.vuecal__today-btn .q-icon {
    color: #fff
}

.vuecal__arrow--prev {
    color: #fff
}

.vuecal__arrow--next {
    color: #fff
}

.vuecal__cell-events-count {
    background-color: #1A4161;
}

.vuecal__cell--today,
.vuecal__cell--current {
    background-color: rgba(136, 236, 191, .4);
}

.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
    background-color: rgba(186, 216, 251, .4);
}

.vuecal__cell--selected:before {
    border-color: rgba(186, 216, 251);
}

/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
    background-color: rgba(195, 255, 225, 0.5);
}

.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
    background-color: rgba(136, 236, 191, 0.25);
}

.vuecal__now-line {
    color: #865FC5;
}

/* DETALLES */
.vuecal__event {
    cursor: pointer;
}

.vuecal__event-title {
    font-size: 1.2em;
    font-weight: bold;
    margin: 4px 0 8px;
}

.vuecal__event-time {
    display: inline-block;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.vuecal__event-content {
    font-style: italic;
}

.vuecal__event.nuevo {
    background-color: rgba(26, 65, 97, 0.9);
    border: 2px solid rgb(255, 255, 255);
    color: #fff;
}

.vuecal__event.notificado {
    background-color: rgba(44, 118, 112, 0.9);
    border: 2px solid rgb(255, 255, 255);
    color: #fff;
}

.vuecal__event.repetitivo {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px);
    /* IE 10+ */
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vuecal__event.repetitivo .vuecal__event-time {
    display: none;
    align-items: center;
}

/* OTROS COLORES */
.custom-q-date .q-date__header {
    background-color: #1A4161;
    color: #ffffff;
}
</style>