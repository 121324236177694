<template>
    <q-card>
        <!-- <span>{{ this.paciente }}</span> -->
        <q-card-section>
            <q-toolbar>
                <q-btn round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close"
                    @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Paciente
                </q-toolbar-title>
                <q-btn v-if="paciente._id == ''" round style="background:#1A4161; color:white" class="q-mr-sm"
                    icon="mdi-content-save" @click="guardarPaciente()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-content-save"
                    @click="PutPaciente()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
        </q-card-section>

        <q-card-section style="max-height: 700px; min-height: 400px;" class="scroll">
            <q-stepper dense alternative-labels v-model="step" header-nav ref="stepper" color="primary" animated>

                <q-step :name="1" :title="titulo1" header-class="q-stepper__title" icon="settings" :done="done1">
                    <FichaIdentificacion></FichaIdentificacion>
                </q-step>
                <q-step :name="2" :title="titulo2" icon="create_new_folder" :done="done2">
                    <AntecedentesHeredofamiliares></AntecedentesHeredofamiliares>
                </q-step>
                <q-step :name="3" :title="titulo3" icon="add_comment" :done="done3">
                    <AntecedentesPersonalesNoPatologicas></AntecedentesPersonalesNoPatologicas>
                </q-step>
                <q-step :name="4" :title="titulo4" icon="add_comment" :done="done4">
                    <AntecedentesPersonalesPatologicas></AntecedentesPersonalesPatologicas>
                </q-step>
                <template v-if="paciente.genero == 'Femenino'">
                    <q-step :name="5" :title="titulo5" icon="add_comment" :done="done5">
                        <AntecedentesGinecoObstetricos></AntecedentesGinecoObstetricos>
                    </q-step>
                </template>
                <template v-if="paciente.genero == 'Masculino'">
                    <q-step :name="6" :title="titulo6" icon="add_comment" :done="done6">
                        <AntecedentesAndrologicos></AntecedentesAndrologicos>
                    </q-step>
                </template>

                <q-step :name="7" :title="titulo7" icon="add_comment" :done="done7">
                    <AntecedentesOncologicos></AntecedentesOncologicos>
                </q-step>
                <q-step :name="8" :title="titulo8" icon="add_comment" :done="done8">
                    <PadecimientoActual></PadecimientoActual>
                </q-step>
            </q-stepper>
            <!-- <span>{{ token }}</span> -->

        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'
import FichaIdentificacion from './FichaIdentificacion.vue'
import AntecedentesHeredofamiliares from './AntecedentesHeredofamiliares.vue'
import AntecedentesPersonalesNoPatologicas from './AntecedentesPersonalesNoPatologicas.vue'
import AntecedentesPersonalesPatologicas from './AntecedentesPersonalesPatologicas.vue'
import AntecedentesGinecoObstetricos from './AntecedentesGinecoObstetricos.vue'
import AntecedentesAndrologicos from './AntecedentesAndrologicos.vue'
import AntecedentesOncologicos from './AntecedentesOncologicos.vue'
import PadecimientoActual from './PadecimientoActual.vue'
export default {
    components: {
        FichaIdentificacion,
        AntecedentesHeredofamiliares,
        AntecedentesPersonalesNoPatologicas,
        AntecedentesPersonalesPatologicas,
        AntecedentesGinecoObstetricos,
        AntecedentesAndrologicos,
        AntecedentesOncologicos,
        PadecimientoActual
    },
    data() {
        return {
            step: 1,
            done1: false,
            done2: false,
            done3: false,
            done4: false,
            done5: false,
            done6: false,
            done7: false,
            done8: false,
            titulo1: 'Ficha de Identificación',
            titulo2: 'Antec. Heredofamiliares',
            titulo3: 'Antec. personales no patologicas',
            titulo4: 'Antec. personales patologicas',
            titulo5: 'Gineco Obstétricos',
            titulo6: 'Antec. Andrológicos',
            titulo7: 'Antec. Oncológicos',
            titulo8: 'Padecimiento actual',
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        paciente() {
            return this.$store.state.pacienteStore;
        },
        fechaElaboracion() {
            moment.locale('es-mx');
            return this.paciente.fechaElaboracion ? moment.utc(this.paciente.fechaElaboracion).format('DD/MMMM/yyyy') : ''
        },
    },
    watch: {

    },
    created() {
    },
    methods: {
        fechaElaboracione() {
            moment.locale('es-mx');
            return this.paciente.fechaElaboracion ? moment.utc(this.paciente.fechaElaboracion).format('DD/MMMM/yyyy') : ''
        },
        fechaInicio() {
            moment.locale('es-mx');
            return this.paciente.padecimientoActual.fechaInicio ? moment.utc(this.paciente.padecimientoActual.fechaInicio).format('DD/MMMM/yyyy') : ''
        },
        async guardarPaciente() {
            this.$store.state.pacienteStore.edad = this.calcularEdad(this.$store.state.pacienteStore.fechaNacimiento)
            console.log(this.paciente)

            if (this.$store.state.pacienteStore.apellidos == "") {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique los apellidos del paciente.' })
                return;
            }

            if (this.$store.state.pacienteStore.nombre == "") {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del paciente.' })
                return;
            }

            if (this.$store.state.pacienteStore.genero == "") {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el genero del paciente.' })
                return;
            }
            // if (this.$store.state.pacienteStore.padecimientoActual.fechaInicio == null) {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha inicial del padecimiento' })
            //     return;
            // }

            if (this.$store.state.pacienteStore.fechaNacimiento == null) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha de nacimiento del paciente' })
                return;
            }

            this.$store.state.pacienteStore.fechaElaboracion = moment().format('YYYY/MM/DD');
            this.$store.state.pacienteStore.antecPersonalesNoPatologicos.tabaquismo.indice = (this.paciente.antecPersonalesNoPatologicos.tabaquismo.numCigarros * this.paciente.antecPersonalesNoPatologicos.tabaquismo.años) / 20
            this.$store.state.pacienteStore.edad = this.calcularEdad(this.$store.state.pacienteStore.fechaNacimiento)
            if (this.paciente.genero == 'Femenino') {
                this.$store.state.pacienteStore.antecAndrologicos = {
                    activa: '',
                    actividadSexual: {
                        edadVidaSexual: 0,
                        numParejas: 0,
                        orientacion: '',
                        mpf: '',
                        practicasRiesgo: '',
                        enfermedadesTS: [],
                    },
                    circuncidado: '',
                    edadPubarca: 0,
                }
            } else {
                this.$store.state.pacienteStore.antecGinecoObstetricos = {
                    activa: '',
                    actividadSexual: {
                        edadVidaSexual: 0,
                        numGestas: 0,
                        numPartos: 0,
                        numCesareas: 0,
                        numAbortos: 0,
                        numParejas: 0,
                        orientacion: '',
                        practicasRiesgo: '',
                        mpf: '',
                        enfermedadesTS: [],
                    },
                    edadMenarca: '',
                    estatusMenopausia: '',
                    menopausia: {
                        cicloMenstrual: '',
                        frecuencia: 0,
                        diasSangrado: 0,
                    },
                    fechaUltimaMenstruacion: null,
                }
            }

            console.log(this.paciente)
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando Paciente. Espere...', messageColor: 'white' })

            try {
                const base_ = this.token.base;
                let response = await axios.post(`Paciente/PostPaciente/${base_}/`, this.paciente)
                console.log(response)

                this.paciente._id = response.data
                this.$store.state.listaPacienteStore.push(this.paciente)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El paciente ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async PutPaciente() {
            // if (this.$store.state.pacienteStore.padecimientoActual.fechaInicio == null) {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha inicial del padecimiento' })
            //     return;
            // }

            // if (this.$store.state.pacienteStore.fechaNacimiento == null) {
            //     this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha de nacimiento del paciente' })
            //     return;
            // }

            if (this.$store.state.pacienteStore.apellidos == "") {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique los apellidos del paciente.' })
                return;
            }

            if (this.$store.state.pacienteStore.nombre == "") {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del paciente.' })
                return;
            }

            if (this.$store.state.pacienteStore.genero == "") {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el genero del paciente.' })
                return;
            }
            this.$store.state.pacienteStore.fechaElaboracion = moment().format('YYYY/MM/DD');
            this.$store.state.pacienteStore.antecPersonalesNoPatologicos.tabaquismo.indice = (this.paciente.antecPersonalesNoPatologicos.tabaquismo.numCigarros * this.paciente.antecPersonalesNoPatologicos.tabaquismo.años) / 20
            this.$store.state.pacienteStore.edad = this.calcularEdad(this.$store.state.pacienteStore.fechaNacimiento)

            if (this.paciente == 'Femenino') {
                this.$store.state.pacienteStore.antecAndrologicos = {
                    activa: '',
                    actividadSexual: {
                        edadVidaSexual: 0,
                        numParejas: 0,
                        orientacion: '',
                        mpf: '',
                        practicasRiesgo: '',
                        enfermedadesTS: [],
                    },
                    circuncidado: '',
                    edadPubarca: 0,
                }
            } else {
                this.$store.state.pacienteStore.antecGinecoObstetricos = {
                    activa: '',
                    actividadSexual: {
                        edadVidaSexual: 0,
                        numGestas: 0,
                        numPartos: 0,
                        numCesareas: 0,
                        numAbortos: 0,
                        numParejas: 0,
                        orientacion: '',
                        practicasRiesgo: '',
                        mpf: '',
                        enfermedadesTS: [],
                    },
                    edadMenarca: '',
                    estatusMenopausia: '',
                    menopausia: {
                        cicloMenstrual: '',
                        frecuencia: 0,
                        diasSangrado: 0,
                    },
                    fechaUltimaMenstruacion: null,
                }
            }

            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Editando Paciente. Espere...', messageColor: 'white' })

            try {
                const base_ = this.token.base;
                let response = await axios.put(`Paciente/PutPaciente/${base_}`, this.paciente)
                console.log(response)

                let indice = this.$store.state.listaPacienteStore.findIndex(x => x._id === this.paciente._id);
                Object.assign(this.$store.state.listaPacienteStore[indice], this.paciente)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El paciente ha sido actualizado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },

        calcularEdad(fechaNacimiento) {
            var fechaNacimientoObj = new Date(fechaNacimiento);

            var fechaActual = new Date();

            var diferenciaEnMilisegundos = fechaActual - fechaNacimientoObj;

            var edad = Math.floor(diferenciaEnMilisegundos / 1000 / 60 / 60 / 24 / 365.25);

            return edad;
        }
    }
}
</script>
<style></style>