<template>
    <div>
        <!-- <q-img class="" src="https://cdn.quasar.dev/img/material.png" style="height: 150px">
            <div class="absolute-bottom bg-transparent">
                <q-avatar size="56px" class="q-mb-sm">
                    <img src="https://cdn.quasar.dev/img/boy-avatar.png">
                </q-avatar>
                <div class="text-weight-bold">{{ token.nombre }}</div>
            </div>
        </q-img> -->
        <q-card class="q-mt-lg shadow-0">
            <q-card-section class="text-center">
                <q-avatar style="width: 150px;height: 150px; font-size: 30px;">
                    <!-- <q-avatar rounded size="150px"> -->
                    <!-- <img :src="urlMedico"> -->
                    <q-img :src="urlMedico" spinner-color="white" style="max-width: 180px; height: 140px;" contain
                        transition="fade" />
                </q-avatar>
                <p style="font-size: 25px; font-weight: bold;">{{ token.username }} </p>
                <p style="font-size: 15px; margin-top: -20px;" lines="1">{{ token.nombre + ' ' + token.apellido }}
                </p>
            </q-card-section>
        </q-card>

        <q-list class="rounded-borders" style="max-width: 350px; ">
            <q-item-label header>Menu</q-item-label>
            <q-separator inset="item" />
            <q-item class="text-primary" clickable v-ripple @click="irPerfil()">
                <q-item-section avatar>
                    <q-icon name="mdi-account" />
                </q-item-section>
                <q-item-section>
                    <q-item-label lines="1">Perfil</q-item-label>
                </q-item-section>
            </q-item>
            <q-separator inset="item" />
            <q-item class="text-primary" clickable v-ripple @click="irEmpleados()">
                <q-item-section avatar>
                    <q-icon name="mdi-account-group" />
                </q-item-section>
                <q-item-section>
                    <q-item-label lines="1">Empleados</q-item-label>
                </q-item-section>
            </q-item>
            <q-separator inset="item" />
            <q-item class="text-primary" clickable v-ripple @click="irListUnidadMedica()">
                <q-item-section avatar>
                    <q-icon name="mdi-hospital-building" />
                </q-item-section>
                <q-item-section>
                    <q-item-label lines="1">Unidad médica</q-item-label>
                </q-item-section>
            </q-item>
            <q-separator inset="item" />
            <q-item class="text-primary" v-ripple>
                <!--  -->
                <q-item-section>
                    <q-input bottom-slots v-model="codigoUnidadMedica" label="Código unidad médica" maxlength="6" dense>
                        <template v-slot:after>
                            <q-btn round dense flat icon="mdi-send" @click="GuardaCodigo()" />
                        </template>
                    </q-input>
                    <!-- <q-item-label lines="1">Unidad médica</q-item-label> -->
                </q-item-section>
            </q-item>
            <!-- <q-separator inset="item" /> -->
        </q-list>

        <!-- <q-footer class="transparent"> -->
        <q-list class="rounded-borders" style="max-width: 350px; ">

            <q-item-label header>Otros</q-item-label>
            <q-separator />

            <q-item class="text-primary" tag="label" v-ripple>
                <q-item-section>
                    <q-item-label>Modo Oscuro</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <q-toggle color="blue" v-model="modoOscuro" val="battery" />
                </q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable active v-ripple @click="salir()">
                <q-item-section>
                    Cerrar Sesión
                </q-item-section>
                <q-item-section avatar>
                    <q-icon name="mdi-logout" />
                </q-item-section>
            </q-item>
            <q-separator />

            <q-item-label class="q-mt-md" header>Soporte Técnico</q-item-label>
            <div class="text-center">
                <q-btn class="q-mr-md" outline round size="md" color="primary" icon="mdi-email-edit-outline"
                    @click="enviarCorreo('soporte@oneanalitica.com', 'Asunto del correo', 'Este es el cuerpo del mensaje.')" />
                <q-btn outline round size="md" color="primary" icon="mdi-whatsapp" @click="enviarWhatsApp()" />
            </div>
            <div class="q-mt-md text-center text-primary">soporte@oneanalitica.com</div>
            <div class="q-mb-md text-center text-primary">+52 1 221 439 4029</div>
        </q-list>

        <!-- </q-footer> -->


    </div>
</template>
<script>
import { ref } from 'vue'
import axios from "axios";
import moment from 'moment'
import { QSpinnerCube } from 'quasar';

export default {

    components: {
    },
    data() {
        return {
            isDarkMode: false,
            codigoUnidadMedica: null,
            urlMedico: require('../../assets/logo_contago.png'),
        }
    },

    watch: {
        // modoOscuro(newVal) {
        //     this.$q.dark.set(newVal);
        // }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        letra() {
            return this.$store.state.usuario.nombre.charAt(0).toUpperCase();
        },
        modoOscuro: {
            get() {
                return this.$store.state.isDark;
            },
            set(value) {
                this.$store.commit('SET_DARK_MODE', value);
                this.$q.dark.set(value);
            }
        }
    },
    created() {
        this.GetPerfilMedico()
    },
    methods: {
        async GetPerfilMedico() {
            try {
                const base_ = this.token.base;
                const idMedico = this.token._id;
                let response = await axios.get(`Configuracion/GetPerfilMedicoAsync/${base_}/${idMedico}`);
                let x = { ...response.data }
                this.urlMedico = x.urlMedico
            } catch (error) {
                console.log(error);
            }
        },

        enviarCorreo(destinatario, asunto, cuerpo) {
            // Codifica los parámetros para manejar caracteres especiales
            const encodedAsunto = encodeURIComponent(asunto);
            const encodedCuerpo = encodeURIComponent(cuerpo);
            const mailtoURL = `mailto:${destinatario}?subject=${encodedAsunto}&body=${encodedCuerpo}`;

            // Redirige al cliente de correo
            window.location.href = mailtoURL;
        },

        enviarWhatsApp() {
            let numero = '5212214394029'
            let mensaje = 'Hola, este es un mensaje enviado desde mi app!'
            // Eliminar caracteres no numéricos del número
            const numeroLimpio = numero.replace(/\D/g, "");
            // Codificar el mensaje para manejar caracteres especiales
            const mensajeCodificado = encodeURIComponent(mensaje);
            // Construir la URL de WhatsApp
            const urlWhatsApp = `https://wa.me/${numeroLimpio}?text=${mensajeCodificado}`;

            // Redirigir al enlace
            // window.location.href = urlWhatsApp;
            window.open(urlWhatsApp, '_blank');
        },

        async salir() {
            this.$q.loading.show({ message: '<b>Cerrando Sesión...</b>' })
            try {
                this.$q.loading.hide()
                this.$store.dispatch("salir");
            } catch (err) {
                console.log(err);
                this.$q.loading.hide()

            }
        },

        irEmpleados() {
            this.$router.push('ListaEmpleados')
        },

        irLogin() {
            this.$router.push('Login2')
        },

        irPerfil() {
            this.$router.push('Perfil')
        },

        irListUnidadMedica() {
            this.$router.push('ListUnidadMedica')
        },

        async GetValidaCodigoUnidadMedica() {
            try {
                let response = await axios.get(`UnidadMedica/GetValidaCodigoUnidadMedicaAsync/${this.codigoUnidadMedica}`)
                return response.data;
            } catch (error) {
                console.log(error);
                return null;
            }
        },

        async GetValidaUnidadMedicaUsuario(_id_usuario, _id_unidad_medica) {
            try {
                let response = await axios.get(`UnidadMedica/GetValidaUnidadMedicaUsuarioAsync/${_id_usuario}/${_id_unidad_medica}`)
                return response.data;
            } catch (error) {
                console.log(error);
                return false;
            }
        },

        async PostUnidadMedicaUsuario(idUsuario, idClinica) {
            try {
                const obj = {
                    _id: "",
                    _id_usuario: idUsuario,
                    _id_unidad_medica: idClinica,
                }
                let response = await axios.post(`UnidadMedica/PostUnidadMedicaUsuarioAsync/`, obj)
                return response.data;
            } catch (error) {
                console.log(error);
                return false;
            }
        },

        async GuardaCodigo() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Validando código...', messageColor: 'white' })
            const idClinica = await this.GetValidaCodigoUnidadMedica();
            const idUsuario = this.token._id;
            if (!idClinica) {
                this.$q.notify({
                    type: 'negative',
                    message: 'El código ingresado no existe, favor de validar e intentar nuevamente',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                this.$q.loading.hide()
                return;
            }

            const previo = await this.GetValidaUnidadMedicaUsuario(idUsuario, idClinica);
            if (previo) {
                this.$q.notify({
                    type: 'warning',
                    message: 'El usuario ya pertenece a la unidad médica',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                this.$q.loading.hide();
                this.codigoUnidadMedica = null;
                return;
            }

            const asigna = await this.PostUnidadMedicaUsuario(idUsuario, idClinica);
            if (!asigna) {
                this.$q.notify({
                    type: 'warning',
                    message: 'Error al asignar, intente nuevamente',
                    actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
                })
                this.$q.loading.hide();
                this.codigoUnidadMedica = null;
                return;
            }

            this.$q.notify({
                type: 'positive',
                message: 'Unidad médica asignada al usuario correctamente',
                actions: [{ label: "Cerrar", color: "white", handler: () => { /* ... */ }, },]
            })

            this.$q.loading.hide()
        },
    }
}
</script>
