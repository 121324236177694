import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isDark: true,

    token: null,
    usuario: null,
    // telefono: null,
    pacienteStore: {
      _id: '',
      estatus: 'Vigente',
      fechaElaboracion: null,
      especialidad: '',
      referencia: '',
      apellidos: '',
      nombre: '',
      fechaNacimiento: null,
      edad: 0,
      genero: '',
      seguroMedico: '',
      numPoliza: '',
      vive: '',
      datosContacto: {
        correo: '',
        pais: '',
        celular: '',
        calle: '',
        numInterior: '',
        numExterior: '',
        codigoPostal: '',
        colonia: '',
        estado: '',
      },
      contactoUrgencia: {
        nombre: '',
        correo: '',
        pais: '',
        celular: '',
      },
      contactoResponsable: {
        nombre: '',
        correo: '',
        pais: '',
        celular: '',
      },
      antecHeredofammiliares: [],
      antecPersonalesNoPatologicos: {
        procedencia: {
          pais: '',
          estado: '',
          municipio: {
            estado: '',
            municipio: '',
          },
        },
        residencia: {
          pais: '',
          estado: '',
          municipio: {
            estado: '',
            municipio: '',
          },
        },
        escolaridad: '',
        religion: '',
        ocupacion: '',
        estadoCivil: '',
        convAnimales: [],
        zoonosis: [],
        actividadesFisicas: [],
        comidasDia: 1,
        calidadAlimentacion: 0,
        intolerancias: [],
        alergias: [],
        hemotipo: '',
        exposicionBiomasa: {
          horas: 0,
          años: 0,
        },
        tabaquismo: {
          numCigarros: 0,
          años: 0,
          indice: 0,
        },
        alcoholismo: [],
        toxicomanias: [],
      },
      antecPersonalesPatologicos: {
        enfermedadesCronicos: [],
        prodedimientosQuirurgicos: [],
        traumatismosPrevios: [],
      },
      antecGinecoObstetricos: {
        activa: '',
        actividadSexual: {
          edadVidaSexual: 0,
          numGestas: 0,
          numPartos: 0,
          numCesareas: 0,
          numAbortos: 0,
          numParejas: 0,
          orientacion: '',
          practicasRiesgo: '',
          mpf: '',
          enfermedadesTS: [],
        },
        edadMenarca: '',
        estatusMenopausia: '',
        menopausia: {
          cicloMenstrual: '',
          frecuencia: 0,
          diasSangrado: 0,
        },
        fechaUltimaMenstruacion: null,
      },
      antecAndrologicos: {
        activa: '',
        actividadSexual: {
          edadVidaSexual: 0,
          numParejas: 0,
          orientacion: '',
          mpf: '',
          practicasRiesgo: '',
          enfermedadesTS: [],
        },
        circuncidado: '',
        edadPubarca: 0,
      },
      antecOncologicos: {
        terapias: [],
        radioterapias: [],
        procedimientos: []
      },
      padecimientoActual: {
        fechaInicio: null,
        sintomas: [],
        diagnosticos: [],
        descripcion: '',
      },
      usuarioCreador: ''
    },
    listaPacienteStore: [],

    indexPaciente: -1,
    indexEmpleado: -1,

    listaEmpleadosStore: [],
    empleadoStore: {},

    pacienteConsultaStore: {
      paciente: ''
    },

    pacienteConstanzaStore: {
      paciente: ''
    },

    consultaStore: {},
    listaConsultasStore: [],
    indexConsulta: -1,

    listaCatalogoAseguradoras: [],
    listaCatalogoPaises: [],
    listaCatalogoDiagnosticosCIE: [],
    listaCatalogoFamiliares: [],
    listaCatalogoEstados: [],
    listaCatalogoMunicipios: [],
    listaCatalogoReligiones: [],
    listaCatalogoOcupaciones: [],
    listaCatalogoEstadosCiviles: [],
    listaCatalogoEscolaridad: [],
    listaCatalogoToxicomanias: [],
    listaCatalogoBebidas: [],
    listaCatalogoHemotipos: [],
    listaCatalogoAlergias: [],
    listaCatalogoIntolerancias: [],
    listaCatalogoActividadesFisicas: [],
    listaCatalogoZoonosis: [],
    listaCatalogoAnimales: [],
    listaCatalogoTratamientos: [],
    listaCatalogoEnfermedadesCronicas: [],
    listaCatalogoArticulaciones: [],
    listaCatalogoHuesos: [],
    listaCatalogoTraumatismos: [],
    listaCatalogoProcedimientos: [],
    listaCatalogoETS: [],
    listaCatalogoMPF: [],
    listaCatalogoTerapias: [],
    listaCatalogoRadioterapias: [],
    listaCatalogoSintomas: [],
    listaCatalogoPartesCuerpo: [],
    listaCatalogoSitiosPrimarios: [],
    listaCatalogoEstudiosAnatomiaPatologica: [],
    listaCatalogoPronosticos: [],
    listaCatalogoMotivosTransfusion: [],
    listaCatalogoTipoReaccionAdversa: [],
    listaCatalogoTipoTransfusion: [],

    listaCatalogoNeurologicos: [],
    listaCatalogoCabezaCuello: [],
    listaCatalogoToraxEspalda: [],
    listaCatalogoAbdomenPelvis: [],
    listaCatalogoExtremidades: [],
    listaCatalogoTegumentos: [],

    listaCatalogoEstudiosImagenologicos: [],
    listaCatalogoEstudiosLaboratorio: [],
    listaCatalogoEstudiosMoleculares: [],
    listaCatalogoMedicamentosObj: [],
    listaCatalogoDiagnosticosString: [],
    listaCatalogoServciosMedicos: [],
    listaCatalogoMotivosConsulta: [],
    listaCatalogoEstadioDiagnostico: [],
    listaCatalogoFrecuenciaAdministracion: [],
    listaCatalogoDuracionTratamiento: [],
    listaCatalogoViasAdministracion: [],

    //CITAS
    listaCitasStore: [],

    //CONSULTORIOS
    listaConsultoriosStore: [],
    consultorioStore: {},

    //USUARIOS ASOCIADOS
    relacionUsuariosStore: [],
    realcionUserStore: {},

    //PDF
    pdfStore: {
      base64: "",
      nombreArchivo: "",
      tipo: "",
      telefono: "",
    },

    //RUTA NOTIFICACIONES
    rutaNotificacionesStore: "https://api-notificaciones.contago.com.mx/api/",
    // rutaNotificacionesStore: "https://localhost:44360/api/",

    //COBROS
    cobrosStore: {},
    listCobrosStore: [],
    idMedicoStore: "",
    idConsultorioStore: "",

    //UNIDAD MEDICA
    listUnidadMedicaStore: [],
    unidadMedicaStore: null,
  },
  mutations: {
    SET_DARK_MODE(state, value) {
      state.isDark = value;
    },
    setToken(state, token) {
      state.token = token
    },

    setUsuario(state, usuario) {
      state.usuario = usuario
    },

    postCita(state, objCita) {
      state.listaCitasStore.push(objCita);
    },

    postPaciente(state, objPaciente) {
      state.listaPacienteStore.push(objPaciente);
    },

    postConsultorio(state, objConsultorio) {
      state.listaConsultoriosStore.push(objConsultorio);
    },

    putConsultorio(state, objConsultorio) {
      let indice = state.listaConsultoriosStore.findIndex(objeto => objeto._id === objConsultorio._id);
      Vue.set(state.listaConsultoriosStore, indice, objConsultorio);

      state.listaConsultoriosStore.push(objConsultorio);
    },

    postUnidadMedica(state, objUnidadMedica) {
      state.listUnidadMedicaStore.push(objUnidadMedica);
    },
  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token-clinic", token)
      router.push({ name: 'Home' })

    },

    autoLogin({ commit }) {
      let token = localStorage.getItem("token-clinic")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'Home' })

      }
    },

    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token-clinic")
      router.push({ name: 'Login' })
    },
  },
  modules: {
  }
})
