<!-- <template>
    <div class="bg-primary window-height window-width row justify-center items-center">

        <div class="column">
            <div class="row">
                
                <q-card square bordered class="q-pa-lg shadow-1">
                    <q-card-section>
                        <q-form class="text-center">
                            <img alt="Contago logo" src="../assets/logo_black.png" style="height: 70px">
                        </q-form>
                    </q-card-section>
                    <q-card-section>
                        <q-form class="q-gutter-md">
                            <span style="color: aquamarine;">{{ this.$store.state.usuarioStore }}</span>

                            
                            <q-input square filled v-model="nombreU" type="usuario" label="Usuario" />
                            <q-input square filled v-model="password" type="password" label="Contraseña"
                                @keyup.enter="validarUsuario()" />
                        </q-form>
                    </q-card-section>
                    <q-card-actions class="q-px-md">
                        <q-btn unelevated color="primary" size="lg" class="full-width" @click="validarUsuario()"
                            label="INICIAR SESIÓN" />
                    </q-card-actions>
                </q-card>
            </div>
        </div>

    </div>

</template>

<script>
import axios from 'axios';
import { QSpinnerGears } from 'quasar';
export default {
    name: 'Login',
    data() {
        return {
            password: '',
            nombreU: "",
        }
    },
    computed: {
    },
    methods: {
        async validarUsuario() {
            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese un nombre de usuario.', timeout: 2000 })
                return
            }
            if (this.nombreU == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese su contraseña.', timeout: 2000 })
                return
            }
            this.$q.loading.show({ message: '<b>Validando usuario...</b>' })
            try {
                let response = await axios.get(`Login/ValidaLogin/dev/${this.nombreU}/${this.password}`);
                this.$q.loading.hide();

                let data = response.data;
                // console.log(data)
                this.$store.state.usuario = { ...data.usuario };
                console.log(data.token);
                this.$store.dispatch("guardarToken", data.token);
                this.$router.push({ name: "Home" });

                this.nombreU = "";
                this.password = "";
            } catch (err) {
                console.log(err);
                this.$q.loading.hide();

                this.$q.notify({ type: 'negative', message: err.response.data });
            }

        }
    }
}
</script>

<style>
.q-card {
    width: 500px;
}
</style> -->
<template>
    <div class="bodys">
        <div class='container-form sign-in ' v-if="isLogin">
            <form class="formulario ">
                <q-card class="shadow-0 transparent">
                    <q-card-section>
                        <h2 class="create-account" v-if="!dataUsuarioValidado">Iniciar Sesión</h2>
                        <h2 class="create-account" v-if="dataUsuarioValidado">Hola {{ dataUsuarioValidado.username }}
                        </h2>
                        <h5 v-if="dataUsuarioValidado">Selecciona tu unidad médica</h5>
                        <div class="iconos">
                            <q-btn outline round size="lg" color="primary" icon="facebook" />
                            <q-btn outline round size="lg" color="primary" icon="mdi-web" />
                            <q-btn outline round size="lg" color="primary" icon="mdi-whatsapp" />
                        </div>
                    </q-card-section>

                    <q-card-section class="q-ml-lg q-mr-lg">
                        <q-form class="q-gutter-md" v-if="itemsUnidadMedica.length == 0">
                            <q-input square filled v-model="nombreU" type="usuario" label="Usuario" />
                            <q-input square filled v-model="passwordU" type="password" label="Contraseña"
                                @keyup.enter="validaUsuarioYPassword()" />
                        </q-form>

                        <q-form class="q-gutter-md" v-if="itemsUnidadMedica.length != 0">
                            <!-- <q-input square filled v-model="nombreU" type="usuario" label="Usuario" /> -->
                            <q-select v-model="selectUnidadMedica" emit-value map-options @filter="filtroUnidadMedica"
                                :options="itemsFiltroUnidadMedica" use-input input-debounce="0" option-label="nombre"
                                dense filled label="Unidad médica" virtual-scroll-slice-size="1" new-value-mode="add" />
                        </q-form>

                    </q-card-section>
                    <q-card-actions class="q-ml-lg q-mr-lg">
                        <template v-if="itemsUnidadMedica.length == 0">
                            <q-btn unelevated color="primary" size="lg" class="full-width "
                                @click="validaUsuarioYPassword()" label="VALIDAR USUARIO" />
                        </template>
                        <template v-if="itemsUnidadMedica.length != 0">
                            <q-btn unelevated color="primary" size="lg" class="full-width q-px-md"
                                @click="iniciaSesion()" label="INGRESAR" />
                        </template>
                    </q-card-actions>
                    <q-card-actions class="q-ml-lg q-mr-lg">
                        <template v-if="itemsUnidadMedica.length != 0">
                            <q-btn unelevated color="primary" size="lg" class="full-width q-px-md"
                                @click="cancelarLogin()" label="CANCELAR" />
                        </template>
                    </q-card-actions>
                </q-card>
            </form>

            <div class="welcome-back">
                <div class="message">
                    <img src="../assets/logo_white.png" width="450px" alt="Logo">
                    <h2 class="create-account" style="font-size: 40px;">Bienvenido</h2>
                    <p style="margin-top: -40px; font-size: 20px;">Si aun no tienes una cuenta por favor regístrese aquí
                    </p>
                    <q-btn class="q-mt-lg" style="width: 200px;" unelevated size="md" rounded color="primary"
                        label="Registrarse" @click="register()" />
                    <!-- <button class="sign-in-btn" @click="toggleForm">Registrarse</button> -->
                </div>
            </div>
        </div>

        <div class='container-form sign-up contenedor-dos' v-else>
            <div class="welcome-back">
                <div class="message">
                    <img src="../assets/logo_white.png" width="450px" alt="Logo">
                    <h2 class="create-account" style="font-size: 40px;">Bienvenido</h2>
                    <p style="margin-top: -40px; font-size: 20px;">Si ya formas parte, inicia sesión aquí.</p>
                    <q-btn class="q-mt-lg" style="width: 200px;" unelevated size="md" rounded color="primary"
                        label="Iniciar sesión" @click="login()" />
                </div>
            </div>
            <form class="formulario-dos" @submit.prevent="register">
                <h2 class="create-account">Crear una cuenta</h2>
                <div class="iconos">
                    <q-btn outline round size="lg" color="primary" icon="facebook" />
                    <q-btn outline round size="lg" color="primary" icon="mdi-web" />
                    <q-btn outline round size="lg" color="primary" icon="mdi-whatsapp" />
                </div>
                <p class="cuenta-gratis">Crear una cuenta gratis</p>

                <div class=" row q-col-gutter-sm q-ml-xl q-mr-xl">

                    <div class="col-12">
                        <q-input v-model="nombreUsuario" square filled dense label="Nombre de usuario" />
                    </div>
                    <div class="col-12">
                        <q-input v-model="nombre" square filled dense label="Nombre" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input v-model="apellidoPaterno" square filled dense label="Apellido Paterno" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input v-model="apellidoMaterno" square filled dense label="Apellido Materno" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input v-model="telefono" mask="(###) ### - ####" unmasked-value square filled dense
                            label="Teléfono" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input v-model="email" square filled dense label="Email" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-select v-model="genero" :options="['Femenino', 'Masculino']" square filled dense
                            label="Género" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input filled dense v-model="fechaNacimiento" label="Fecha de Nacimiento" mask="date"
                            placeholder="yyyy/MM/dd">
                            <template v-slot:after>
                                <q-icon name="mdi-calendar" class="cursor-pointer">
                                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                        <q-date v-model="fechaNacimiento">
                                            <div class="row items-center justify-end">
                                                <q-btn v-close-popup label="Ok" color="primary" flat />
                                            </div>
                                        </q-date>
                                    </q-popup-proxy>
                                </q-icon>
                            </template>
                        </q-input>
                    </div>
                    <div class="col-12">
                        <q-input dense square v-model="password" filled :type="isPwd ? 'password' : 'text'"
                            label="Password">
                            <template v-slot:append>
                                <q-icon :name="isPwd ? 'mdi-lock-off-outline' : 'mdi-lock-outline'"
                                    class="cursor-pointer" @click="isPwd = !isPwd" />
                            </template>
                        </q-input>
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input v-model="cedula" square filled dense label="Cédula Profesional" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input dense square v-model="codigoEmpresa" filled label="Código">
                        </q-input>
                    </div>
                    <div class="col-12">
                        <div class="q-gutter-sm">
                            <q-checkbox v-model="modelAviso" color="secondary" true-value="yes" false-value="no">
                            </q-checkbox>
                            ¿Confirma que ha leído y aceptado el
                            <a href="/AVISO DE PRIVACIDAD.pdf" target="_blank" class="text-primary q-pt-sm">
                                aviso de privacidad
                            </a>?
                        </div>
                    </div>
                    <div class="col-12">
                        <q-btn color="primary" class="full-width" label="Registrarse" @click="guardarEmpleado()" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { QSpinnerCube } from 'quasar'
import { QSpinnerGears } from 'quasar';
export default {
    data() {
        return {
            passwordU: '',
            nombreU: "",
            isPwd: true,

            name: '',
            email: '',
            isSignUp: true, // Controla la vista del formulario
            isLogin: true,

            empleado: {
                _id: '',
                username: '',
                nombre: '',
                apellido: '',
                password: '',
                email: '',
                fechaNacimiento: '',
                genero: '',
                status: 'active',
                telefono: '',
                group: '',
                usuarioCreador: '',
            },

            cedula: '',
            nombreUsuario: '',
            nombre: '',
            apellidoPaterno: '',
            apellidoMaterno: '',
            telefono: '',
            email: '',
            genero: '',
            fechaNacimiento: '',
            password: '',

            //LISTADO DE EMPRESAS
            itemsUnidadMedica: [],
            itemsFiltroUnidadMedica: [],
            selectUnidadMedica: null,
            dataUsuarioValidado: null,

            //CODIGO PARA LIGAR CON LA EMPRESA
            codigoEmpresa: null,
            modelAviso: 'no'
        };
    },
    computed: {
        fechaMasked() {
            moment.locale('es-mx');
            return this.fechaNacimiento ? moment.utc(this.fechaNacimiento).format('DD/MMMM/yyyy') : ''
        },
    },
    methods: {
        toggleForm() {
            this.isSignUp = !this.isSignUp;
        },
        register() {
            this.isLogin = false
        },
        login() {
            this.isLogin = true
        },

        async validaUsuarioYPassword() {
            const nombre = this.nombreU;
            const password = this.passwordU
            if (nombre == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese un nombre de usuario.', timeout: 2000 })
                return
            }
            if (password == '') {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Ingrese su contraseña.', timeout: 2000 })
                return
            }

            this.$q.loading.show({ message: '<b>Validando usuario...</b>' })
            try {
                let response = await axios.get(`Login/ValidaUsuarioAsync/${nombre}/${password}`);
                console.log(response.data)
                this.dataUsuarioValidado = { ...response.data }
                await this.validaConsultorios(this.dataUsuarioValidado._id)
                this.$q.loading.hide();
            } catch (error) {
                console.log(error)
                this.$q.notify({ type: 'negative', message: error.response.data });
                this.$q.loading.hide();
            }
        },

        async validaConsultorios(_id_usuario) {
            this.itemsUnidadMedica = [];
            try {
                let response = await axios.get(`UnidadMedica/GetUnidadMedicaUsuarioAsync/${_id_usuario}`);
                this.itemsUnidadMedica = [...response.data];
            } catch (error) {
                console.log(error);
            }
        },

        cancelarLogin() {
            this.itemsUnidadMedica = [];
            this.selectUnidadMedica = null;
            this.nombreU = "";
            this.passwordU = "";
            this.dataUsuarioValidado = null;
        },

        //DATOS DE LAS UNIDADES MEDICAS
        filtroUnidadMedica(val, update, abort) {
            update(() => {
                const needle = val.toLocaleLowerCase()
                this.itemsFiltroUnidadMedica = this.itemsUnidadMedica.filter(v => v.nombre.toLocaleLowerCase().indexOf(needle) > -1)
            },
                ref => {
                    if (val !== '' && this.itemsFiltroUnidadMedica.length > 0) {
                        ref.setOptionIndex(-1)
                        ref.moveOptionSelection(1, true)
                    }
                })
        },

        async iniciaSesion() {
            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            if (!this.selectUnidadMedica) {
                this.$q.notify({ type: 'info', icon: 'mdi-information', message: 'Seleccione su unidad médica', timeout: 2000 })
                return
            }

            this.$q.loading.show({ message: '<b>Validando información...</b>' })
            try {
                const objToken = {
                    _id: this.dataUsuarioValidado._id,
                    username: this.dataUsuarioValidado.username,
                    nombre: this.dataUsuarioValidado.nombre,
                    apellido: this.dataUsuarioValidado.apellido,
                    telefono: this.dataUsuarioValidado.telefono,
                    email: this.dataUsuarioValidado.email,
                    group: this.dataUsuarioValidado.group,
                    _id_unidad_medica: this.selectUnidadMedica._id_unidad_medica,
                    base_unidad_medica: this.selectUnidadMedica.rfc,
                };
                let response = await axios.post(`Login/ObtieneTokenAsync/`, objToken);
                this.$q.loading.hide();
                const data = response.data;
                this.$store.state.usuario = { ...data };
                console.log(data);
                this.$store.dispatch("guardarToken", data);
                this.$router.push({ name: "Home" });
                this.nombreU = "";
                this.passwordU = "";
                this.selectUnidadMedica = null;
            } catch (err) {
                console.log(err);
                this.$q.loading.hide();
                this.$q.notify({ type: 'negative', message: err.response.data });
            }
        },

        async ValidarCedula() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 110, message: 'Validando Información. Espere...', messageColor: 'white' })
            let objeto = {
                nombre: this.normalizarTexto(this.nombre),
                paterno: this.normalizarTexto(this.apellidoPaterno),
                materno: this.normalizarTexto(this.apellidoMaterno)
            }
            try {
                let response = await axios.post('https://api.constanza.deepaxiom.com/scraper_cedulas/buscar', objeto)
                console.log(response)
                this.$q.loading.hide()
                let a = this.normalizarTexto(this.nombre)
                let b = this.normalizarTexto(this.apellidoPaterno)
                let c = this.normalizarTexto(this.apellidoMaterno)
                let nombreCompleto = a + ' ' + b + ' ' + c
                return response.data.resultados.some(r => r.nombre_completo === nombreCompleto && r.cedula === this.cedula && r.valido == true);
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()

                return false
            }
        },

        async guardarEmpleado() {
            if (this.cedula == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la cédula profecional.' })
                return;
            }
            if (this.nombreUsuario == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del usuario' })
                return;
            }
            if (this.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su nombre' })
                return;
            }
            if (this.apellidoPaterno == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su apellido paterno' })
                return;
            }
            if (this.apellidoMaterno == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su apellido materno' })
                return;
            }
            if (this.telefono == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su número teléfonico' })
                return;
            }
            if (this.email == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su correo electronico.' })
                return;
            }

            if (this.genero == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su genero' })
                return;
            }
            if (this.fechaNacimiento == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique la fecha de nacimiento.' })
                return;
            }
            if (this.password == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el password' })
                return;
            }

            if (!this.codigoEmpresa || this.codigoEmpresa == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el código de invitación' })
                return;
            }

            if (this.modelAviso == 'no') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Debe leer y aceptar el aviso de privacidad.' })
                return;
            }


            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Validanod datos. Espere...', messageColor: 'white' })
            //VALIDAMOS QUE EL CODIGO EXISTA
            const idUnidadMedica = await this.GetValidaCodigoUnidadMedica();
            console.log(idUnidadMedica)
            if (!idUnidadMedica) {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Código inválido' })
                this.$q.loading.hide()
                return;
            }

            let respuesta = await this.ValidarCedula();

            let empleado = {
                _id: '',
                username: this.nombreUsuario,
                nombre: this.nombre,
                apellido: this.apellidoPaterno + ' ' + this.apellidoMaterno,
                password: this.password,
                email: this.email,
                fechaNacimiento: this.fechaNacimiento,
                genero: this.genero,
                status: 'active',
                telefono: this.telefono,
                group: 'Administrador',
                usuarioCreador: 'Original',
                cedula: this.cedula,
                fechaCreacion: moment().format('YYYY/MM/DD')
            }
            if (respuesta) {

                try {
                    let response = await axios.post(`Login/PostRegistroNuevo/${idUnidadMedica}`, empleado)
                    console.log(response)

                    this.$q.loading.hide()
                    this.$q.notify({ type: 'positive', message: `La cuenta se ha creado exitosamente.` })
                    this.$q.notify({ position: 'top-right', type: 'positive', message: `Puede acceder con su nombre de usuario y password.` })
                    this.isLogin = true
                    this.cedula = ''
                    this.nombreUsuario = ''
                    this.nombre = ''
                    this.apellidoPaterno = ''
                    this.apellidoMaterno = ''
                    this.telefono = ''
                    this.email = ''
                    this.genero = ''
                    this.fechaNacimiento = ''
                    this.password = ''
                } catch (error) {
                    console.log(error)
                    this.$q.loading.hide()
                    this.$q.notify({ type: 'negative', message: error.response.data, color: 'red' })
                }
            } else {

                await axios.post('Login/PostRegistrosFallidos/dev/', empleado)
                this.$q.notify({ type: 'negative', message: 'Los datos son incorectos, verifique su informacion e intentelo de nuevo.', color: 'red' })

            }
        },

        async PostCodigoNuevo(_id, username) {
            try {
                let CodigoNuevo = await this.FuncionGeneraCodigo(6);
                let Codigo = CodigoNuevo;
                let objCodigo = {
                    _id: "",
                    id_usuario_principal: _id,
                    usuario_principal: username,
                    codigo: Codigo,
                    fecha_creacion: new Date(),
                }
                let response = await axios.post("Agenda/PostCodigoAsync/dev/", objCodigo)
                console.log('Codigo guardado')
            } catch (error) {
                console.log(error)
            }
        },

        async GetValidaCodigoUnidadMedica() {
            try {
                let response = await axios.get(`UnidadMedica/GetValidaCodigoUnidadMedicaAsync/${this.codigoEmpresa}`)
                return response.data;
            } catch (error) {
                console.log(error);
                return null;
            }
        },

        async FuncionGeneraCodigo(longitud) {
            const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let codigo = '';
            for (let i = 0; i < longitud; i++) {
                const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
                codigo += caracteres[indiceAleatorio];
            }
            return codigo;
        },

        normalizarTexto(texto) {
            return texto
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()
                .trim();
        }
    }
};
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.bodys::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/4.jpg') center/cover no-repeat;
    z-index: -1;
}

.bodys {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background-image: url('../assets/4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    /* Hace que el fondo sea fijo */
    color: #fff;
}

.container-form {
    width: 80%;
    height: 70vh;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s ease-out;
}

.welcome-back {
    display: flex;
    align-items: center;
    height: 70vh;
    text-align: center;
    padding: 3rem;
}

.message {
    padding: 1rem;
}

.message h2 {
    font-size: 1.7rem;
    padding: 1rem 0;
}

/* .message button {
    padding: 1rem;
    font-weight: 400;
    background-color: #4a4aee;
    border-radius: 2rem;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 2rem;
    transition: all 0.3s ease-in;
    color: #fff;
} */

.message button:hover {
    background-color: #6464f8;
}

.formulario {
    width: 500px;
    /* padding: 1rem; */
    padding-bottom: 3rem;
    background-color: rgba(51, 51, 51, 0.6);
    text-align: center;
}

.formulario-dos {
    width: 700px;
    /* padding: 1rem; */
    padding-bottom: 3rem;

    /* margin: 2rem; */
    background-color: rgba(51, 51, 51, 0.6);
    text-align: center;
}

.create-account {
    font-size: 2rem;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.iconos {
    width: 200px;
    display: flex;
    justify-content: space-around;
    margin: auto;
}

.border-icon {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border: solid thin white;
    border-radius: 50%;
    font-size: 1.5rem;
    transition: all 0.3s ease-in;
}

.border-icon:hover {
    background-color: #4a4aee;
    cursor: pointer;
}

.cuenta-gratis {
    padding: 1rem 0;
}

.formulario input {
    width: 70%;
    display: block;
    margin: auto;
    margin-bottom: 2rem;
    background-color: transparent;
    border: none;
    border-bottom: white thin solid;
    text-align: center;
    outline: none;
    padding: 0.2rem 0;
    font-size: 0.9rem;
    color: white;
}

.formulario input[type="submit"] {
    width: 60%;
    margin: auto;
    border: solid thin white;
    padding: 0.7rem;
    border-radius: 2rem;
    background-color: white;
    font-weight: 600;
    margin-top: 3rem;
    font-size: 0.8rem;
    cursor: pointer;
    color: #222;
}

@media (max-width: 1200px) {
    .container-form {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    /* .contenedor-uno {
        display: none;
    } */
}
</style>