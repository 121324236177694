<template>
    <q-card style="min-width: 800px;">
        <q-card-section>
            <q-toolbar>
                <q-btn round dense style="background:#1A4161; color:white" class="q-mr-sm" icon="mdi-close"
                    @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
                <q-toolbar-title>
                    Unidad médica
                </q-toolbar-title>
                <q-btn v-if="unidadMedica._id == ''" round dense style="background:#1A4161; color:white" class="q-mr-sm"
                    icon="mdi-content-save" @click="postUnidadMedica()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>
                <q-btn v-else round dense style="background:#1A4161; color:white" class="q-mr-sm"
                    icon="mdi-content-save" @click="putUnidadMedica()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section class="scroll">
                <div class="row q-col-gutter-md">
                    <div class="col-12  ">
                        <q-input v-model="unidadMedica.nombre" dense filled label="Nombre" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input v-model="unidadMedica.rfc" dense filled label="RFC" />
                    </div>
                    <div class="col-12 col-md-6">
                        <q-input v-model="unidadMedica.razon_social" dense filled label="Razón social" />
                    </div>
                    <div class="col-12  ">
                        <q-input v-model="unidadMedica.liga_maps" dense filled label="Liga maps" />
                    </div>
                    <div class="col-12  ">
                        <q-input v-model="unidadMedica.licencia" dense filled label="Num. Licencia" />
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {

    data() {
        return {

        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        unidadMedica() {
            return this.$store.state.unidadMedicaStore;
        },

    },

    created() {
    },
    methods: {

        async postUnidadMedica() {
            const valida = await this.valida()
            if (!valida) {
                return;
            }

            let obj = this.$store.state.unidadMedicaStore;
            obj.codigo = await this.FuncionGeneraCodigo(6);

            try {
                this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando...', messageColor: 'white' })
                const id = this.token._id;
                let response = await axios.post(`UnidadMedica/PostUnidadMedicaAsync/${id}`, obj);
                this.$q.loading.hide()
                this.$q.notify({
                    type: 'positive',
                    message: `Unidad médica guardada con éxito.`,
                    actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
                })
                obj._id = response.data;
                this.$store.commit('postUnidadMedica', obj);
                this.cierraVentana();
            } catch (error) {
                console.log(error);
                this.$q.notify({
                    type: 'negative',
                    message: `Error al guardar, intente nuevamente.`,
                    actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
                })
                this.$q.loading.hide()
            }
        },

        async putUnidadMedica() {

        },

        async valida() {
            const obj = this.$store.state.unidadMedicaStore;
            if (!obj.nombre || obj.nombre === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el nombre de la unidad médica.`,
                    actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
                })
                return false;
            }

            if (!obj.rfc || obj.rfc === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique el RFC de la unidad médica.`,
                    actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
                })
                return false;
            }

            if (!obj.razon_social || obj.razon_social === "") {
                this.$q.notify({
                    type: 'warning',
                    message: `Indique la razón social de la unidad médica.`,
                    actions: [{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } }]
                })
                return false;
            }
            return true;
        },

        async FuncionGeneraCodigo(longitud) {
            const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let codigo = '';
            for (let i = 0; i < longitud; i++) {
                const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
                codigo += caracteres[indiceAleatorio];
            }
            return codigo;
        },

        cierraVentana() {
            this.$emit('cierraVentana')
        },

    }
}
</script>
<style></style>